		$(".counter_field").on("change", function(e){
			if($(this).val() < 1 || $(this).val().match(/\D+/g)){
				$(this).val(1);
			}
			if(e.target.classList.contains("event-proceed")){
				e.target.closest("form").submit();
			}
		});

		$(".counter .btn-minus").on("click tap", function(e)
		{
			if(!e.currentTarget.classList.contains("event-proceed")){
				e.preventDefault();
			}
			var counter_field = $(this).closest(".counter").find(".counter_field");
			var counter_field_value = counter_field.val();
			if(counter_field_value == 0){

				return
			}
			else{
				counter_field_value --;
				counter_field.val(counter_field_value);
			}


		});

		$(".counter .btn-plus").on("click tap", function(e)
		{
			if (!e.currentTarget.classList.contains("event-proceed")){
				e.preventDefault();
			}

			var counter_field = $(this).closest(".counter").find(".counter_field");
			var counter_field_value = counter_field.val();
			counter_field_value ++;
			counter_field.val(counter_field_value);
		});
