// Scroll to
const allScrollToBtns = document.querySelectorAll('.scroller');
//console.log('scroller loaded');

// $(document).ready(function(){
//   TweenMax.to(window, 1.5, {scrollTo:500, ease:Power2.easeInOut});
// })


allScrollToBtns.forEach(function(btn){

  btn.addEventListener("click", function(e) {

    e.preventDefault();
    if(e.target.classList.contains("scroller")){
      const targetDiv = this.getAttribute('data-scrollto');
      const target = document.querySelector("#"+targetDiv+"");
      const targetY = target.offsetTop;
      //console.log('posuvam');
      //console.log(targetY);

      gsap.to(window, 1.5, {scrollTo:targetY-100, ease:Power2.easeInOut});

      }
  })
});
