
const offCanvas = function(target){
  // Zadefinujeme zakladne premenne
  

  this.callers = document.querySelectorAll('.offcanvas-trigger[data-target=' + target.getAttribute('id') + ']');;
  this.target = document.querySelector('#' + target.getAttribute('id'));
  this.closers = this.target.querySelectorAll('.close, .close-offcanvas');
  this.targetOpened = false;

  if(target.classList.contains('active')){
    this.openTarget();
  }

  
  // Nastartujeme vsetky event listenery
  this.addEventListeners();
  //console.log(this);
}

// *************************************************************
// OPEN TARGET
// *************************************************************

offCanvas.prototype.openTarget = function openTarget(){
  const this1 = this;
  const page = document.querySelector("#page-wraper");

  //console.log('otvaram offcanvas');
  

  this.target.classList.add("active");
  this.targetOpened = true;

  // 2. ...in some event handler after showing the target element...disable body scroll

  scrollLock.disablePageScroll(this1.target);

  document.body.addEventListener("keyup", function(e){
    if(e.key === "Escape"){
      this1.closeTarget();
      this.removeEventListener(e.type, arguments.callee);
    }
  });

  // if(this1.caller.getAttribute('data-move-page') === "true"){
  //   page.classList.toggle('move-page');
  // }

  page.addEventListener("click", function(e){
    this1.closeTarget();
    this.removeEventListener(e.type, arguments.callee);
  })







}


// *************************************************************
// CLOSE TARGET
// *************************************************************
offCanvas.prototype.closeTarget = function closeTarget(){
  const this1 = this;
  const page = document.querySelector("#page-wraper");

  this.target.classList.remove("active");
  this.targetOpened = false;
  if(page.classList.contains('move-page')){
    page.classList.toggle('move-page')
  }
  // 3. ...in some event handler after hiding the target element...
  scrollLock.enablePageScroll(this1.target)


  //console.log("target closed");
}

// *************************************************************
// ERROR HANDLING
// *************************************************************
offCanvas.prototype.msg = function msg(msg){
  console.log(msg[0]+": "+msg[1]);
}

// *************************************************************
// addEventListeners
// *************************************************************
offCanvas.prototype.addEventListeners = function addEventListeners(){
  const this1 = this;

  this1.closers.forEach(function(closer){
    closer.addEventListener("click", function(e){
      this1.closeTarget();
    });
  })

  this1.callers.forEach(function(caller){
    caller.addEventListener("click", function (e) {
      // toto je tu kvoli zabraneniu dvojkliku pri zariadeniach s touch podporou
      e.stopPropagation();
      e.preventDefault();

      if (this1.targetOpened === false) {
        this1.openTarget();
      } else {
        this1.closeTarget();
      }
    })
  })

}

// Najdeme vsetky offcanvas triggery
const allOffCanvasCallers = document.querySelectorAll('.offcanvas');
const callers = new Array();

// Pre kazdy najdeny trigger vytvor novu classu
allOffCanvasCallers.forEach(function(offcanvas){

   callers.push(offcanvas, new offCanvas(offcanvas));
   
})
