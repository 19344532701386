
import "./cart_payment";
//import "./cookie_consent";
import "./counter";
import "./form-validation";
import "./mobile-menu";
import "./numbered-index";
import "./offcanvas";
import "./product-variant-selector";
import "./reveal-password";
import "./scroll-animated";
import "./scroller";
import "./shipping_adressbook";

$(document).ready(function() {


	//  ######  ##       #### ########  ########  ######  ##     ##  #######  ##      ## 
	// ##    ## ##        ##  ##     ## ##       ##    ## ##     ## ##     ## ##  ##  ## 
	// ##       ##        ##  ##     ## ##       ##       ##     ## ##     ## ##  ##  ## 
	//  ######  ##        ##  ##     ## ######    ######  ######### ##     ## ##  ##  ## 
	//       ## ##        ##  ##     ## ##             ## ##     ## ##     ## ##  ##  ## 
	// ##    ## ##        ##  ##     ## ##       ##    ## ##     ## ##     ## ##  ##  ## 
	//  ######  ######## #### ########  ########  ######  ##     ##  #######   ###  ###  


		document.querySelectorAll('.product-slideshow_container').forEach(function (slideshowContainer) {

			const slideShow = slideshowContainer.querySelector('.product-slideshow');
			const flick = new Flickity(slideShow, {
				cellAlign: 'left',
				//groupCells: true,
				wrapAround: true,
				setGallerySize: false,
				cellSelector: '.product',
				prevNextButtons: false
				//contain: true,
			});

			const prevBtn = slideshowContainer.querySelector('.prev');
			const nextBtn = slideshowContainer.querySelector('.next');

			// Custom previous btn
			prevBtn.addEventListener('click', e =>{
				flick.previous();
			})

			// Custom next btn
			nextBtn.addEventListener('click', e =>{
				flick.next();
			})

		});



		



		// VSETKY MINI SLIDESHOW
		const allMiniSlideshows = document.querySelectorAll('.mini-content-slideshow');

		allMiniSlideshows.forEach(function (slideshow) {
			
			const randomAutoplay = Math.round(Math.random() * 2 + 3) * 1000;

			const miniSlideshow = slideshow.querySelector('.slideshow');
			const miniFlickity = new Flickity(miniSlideshow, {
				prevNextButtons: false
			})

			// Ak existuju slideshow controls, tak pridaj funkcnost prev a next
			if(slideshow.querySelector('.slideshow-controls')){
				const prev = slideshow.querySelector('.prev');
				const next = slideshow.querySelector('.next');

				// Custom previous btn
				prev.addEventListener('click', e =>{
					miniFlickity.previous();
				})

				// Custom next btn
				next.addEventListener('click', e =>{
					miniFlickity.next();
				})
			}

		})


	/*
	===========================
	########  #######     ########  #######  ########
	   ##    ##     ##       ##    ##     ## ##     ##
	   ##    ##     ##       ##    ##     ## ##     ##
	   ##    ##     ##       ##    ##     ## ########
	   ##    ##     ##       ##    ##     ## ##
	   ##    ##     ##       ##    ##     ## ##
	   ##     #######        ##     #######  ##
	===========================
	*/

	


	$('#to_top').on("click tap", function(){
		//or to scroll to the element with the ID "#someID":
		TweenMax.to($(window), 1, {scrollTo:"#site_root"});
	})


	// ########     ###    ######## #### ##    ##  ######
	// ##     ##   ## ##      ##     ##  ###   ## ##    ##
	// ##     ##  ##   ##     ##     ##  ####  ## ##
	// ########  ##     ##    ##     ##  ## ## ## ##   ####
	// ##   ##   #########    ##     ##  ##  #### ##    ##
	// ##    ##  ##     ##    ##     ##  ##   ### ##    ##
	// ##     ## ##     ##    ##    #### ##    ##  ######

	$('.rating').each( function(){
		var activeStars = $(this).attr("data-stars");
		var totalStars = $(this).children(".star").length;

		var inactiveClass = "fa-star-o";
		var activeClass = "fa-star";


		for (i = 0; i < activeStars; i++) {
			$(this).children(".star").eq(i).removeClass(inactiveClass).addClass(activeClass);
		}
	})



		/*
		===========================

		########   #######   #######  ########  ######  ######## ########     ###    ########
		##     ## ##     ## ##     ##    ##    ##    ##    ##    ##     ##   ## ##   ##     ##
		##     ## ##     ## ##     ##    ##    ##          ##    ##     ##  ##   ##  ##     ##
		########  ##     ## ##     ##    ##     ######     ##    ########  ##     ## ########
		##     ## ##     ## ##     ##    ##          ##    ##    ##   ##   ######### ##
		##     ## ##     ## ##     ##    ##    ##    ##    ##    ##    ##  ##     ## ##
		########   #######   #######     ##     ######     ##    ##     ## ##     ## ##

		===========================
		*/

		// AKTIVOVANIE TOOLTIPOV
		$('[data-toggle="tooltip"]').tooltip();

		function toggleDropdown(e) {
			const _d = $(e.target).closest('.dropdown'),
				_m = $('.dropdown-menu', _d);
			setTimeout(function () {
				const shouldOpen = e.type !== 'click' && _d.is(':hover');
				_m.toggleClass('show', shouldOpen);
				_d.toggleClass('show', shouldOpen);
				$('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
			}, e.type === 'mouseleave' ? 300 : 0);
		}

		$('body')
			.on('mouseenter mouseleave', '.dropdown-toggle', toggleDropdown)
			.on('click', '.dropdown-menu a', toggleDropdown);


		/*
		 ######  ##     ##  ######
		##    ## ##     ## ##    ##
		##       ##     ## ##
		 ######  ##     ## ##   ####
		      ##  ##   ##  ##    ##
		##    ##   ## ##   ##    ##
		 ######     ###     ######
		*/
			$('img.svg').each(function(){
			    var $img = jQuery(this);
			    var imgID = $img.attr('id');
			    var imgClass = $img.attr('class');
			    var imgURL = $img.attr('src');



			    jQuery.get(imgURL, function(data) {
			        // Get the SVG tag, ignore the rest
			        var $svg = jQuery(data).find('svg');

			        // Add replaced image's ID to the new SVG
			        if(typeof imgID !== 'undefined') {
			            $svg = $svg.attr('id', imgID);
			        }
			        // Add replaced image's classes to the new SVG
			        if(typeof imgClass !== 'undefined') {
			            $svg = $svg.attr('class', imgClass+' replaced-svg');
			        }

			        // Remove any invalid XML tags as per http://validator.w3.org
			        $svg = $svg.removeAttr('xmlns:a');

			        // Replace image with new SVG
			        $img.replaceWith($svg);

			    }, 'xml');
			});

			//
			// #### ##     ##  ######
			//  ##  ###   ### ##    ##
			//  ##  #### #### ##
			//  ##  ## ### ## ##   ####
			//  ##  ##     ## ##    ##
			//  ##  ##     ## ##    ##
			// #### ##     ##  ######

			// Prehodit vsetky obrazky ako bcg img
			// const allContentImages = document.querySelectorAll(".content-image");

			// allContentImages.forEach(function(contentImage){
			// 	const originalImage = contentImage.querySelector("img");
			// 	const originalImageURL = originalImage.getAttribute("src");

			// 	//console.log(originalImageURL);

			// 	contentImage.style.backgroundImage = "url('"+originalImageURL+"')";
			// 	//contentImage.style.background = ""
			// 	//console.log(contentImage);
			// 	originalImage.style.visibility = "hidden";
			// })





		/*
		===========================
		POZNAMKA
		===========================
		*/

		const productGroups = document.querySelectorAll(".eshop-table__group");

		productGroups.forEach(function (group) {

			const header = group.querySelector(".group__head");
			const subsection = group.querySelector(".group__subsection");

			header.addEventListener('click', function (e) {
				//console.log('otvaram');
				e.currentTarget.classList.toggle('expanded')
				//subsection.classList.toggle('show');
				$(subsection).collapse("toggle");
			});
		});


}); ///END DOCUMENT.READY
