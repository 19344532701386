let shippingAdressbook = (() => {

    const adressBook = document.querySelectorAll('.delivery-adress-book');

    if (adressBook.length == 0) {
        //ZIADNE ADRESY, VYPNI FUNKCIU A NEHADZ ERROR
        return;
    };

    const countForms = function (whereToCount) {
        return whereToCount.querySelectorAll('.delivery-adresses__adress').length;
    }

    const createAdressForm = function (iterNumber, parentTarget){
        const template = parentTarget.querySelector('.adress-tmp');
        const node = document.importNode(template.content, true);

        //Najdi tlacidlo odstranenia (x) a vymaz ho, kedze kym adresa nieje zapisana, nema zmysel tam toto tlacidlo ukazovat
        const removeBtn = node.querySelector('.remove-trigger');
        if(removeBtn){
            removeBtn.parentNode.removeChild(removeBtn);
        }
        //Zmen poradove cislo adresy (adresa 1,2,3 ...)
        node.querySelector('.form-header__name .number').textContent = iterNumber;

        //NAJDI VSETKY ID-cka a veci ktore spajaju inputy, labely a error msg 
        // a dopln k nim iterator (kvoli validacii formov)
        allIterElements = node.querySelectorAll('input, .input_msg, label, select');
        allIterElements.forEach(function(element){
            switch (element.tagName) {
                case 'INPUT':
                    const idAttr = element.getAttribute('id');
                    element.setAttribute('id', idAttr + iterNumber);

                    const inputName = element.getAttribute('name');
                    const inputNameReplaced = inputName.replace(/\[(\d+)\]/, "[" + iterNumber +"]");
                    element.setAttribute('name', inputNameReplaced);
                    
                    
                    break;
                case 'SELECT':
                    const selectAttr = element.getAttribute('id');
                    element.setAttribute('id', selectAttr + iterNumber);

                    const selectName = element.getAttribute('name');
                    const selectReplaced = selectName.replace(/\[(\d+)\]/, "[" + iterNumber + "]");
                    element.setAttribute('name', selectReplaced);
                    break;
                case 'LABEL':
                    const labelAttr = element.getAttribute('for');
                    element.setAttribute('for', labelAttr + iterNumber);
                    break;
                case 'DIV':
                    const dataAttr = element.getAttribute('data-id');
                    element.setAttribute('data-id', dataAttr+iterNumber);
                    break;
            
                default:
                    break;
            }
            
        })
        
        //Vrat cely template
        return node;
    }

    const addAdress = function (howMany, trigger) {
        /*
        v prvom rade zisti z ktorej instancie adressbook-u bol
        skript spusteny, nasledne porataj v tejto instancii uz existujuce
        instancie formularu a nasledne pridaj na ich koniec tolko kolko treba (howMany)
        */
        const parentAdressBook = trigger.closest('.delivery-adress-book');
        const howManyFormsExist = countForms(parentAdressBook);

        for (let iter = howManyFormsExist; iter < (howManyFormsExist + howMany); iter++) {
            //PRIDAJ SAMOTNE FORMY DO DOM-u
            //console.log("vytvaram form");
            parentAdressBook.insertBefore(createAdressForm(iter + 1, parentAdressBook), trigger);
           
        }
    }



    adressBook.forEach(function (adressBook) {

        const addAdressBtn = adressBook.querySelector('.add_adress');
        addAdressBtn.addEventListener('click', function (e) {
            e.preventDefault();
            addAdress(1, e.currentTarget);
        });

    });





    return {
        adressBook,
        addAdress
    }

})();