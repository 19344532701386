const allRevealPassButtons = document.querySelectorAll(".reveal-password");

allRevealPassButtons.forEach(function(btn){

  const btnTarget = btn.getAttribute('data-pass-id');
  const targetEl = document.querySelector('#'+btnTarget);
  const btnIcon = btn.querySelector('.icon');

  let passVisible = false;

  btn.addEventListener('click', function(e) {
    //console.log('ukaz heslo ne');
    switch (passVisible) {
      case true:
        btnIcon.innerHTML = "visibility";
        targetEl.setAttribute('type', 'password');
        passVisible = false;
        break;

      case false:
        btnIcon.innerHTML = "visibility_off";
        targetEl.setAttribute('type', 'text');
        passVisible = true;
        break;
      default:

    }
  })
})
