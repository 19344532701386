export const allMethods = document.querySelectorAll('.shopping-cart__method input');

allMethods.forEach(function(method){

  method.addEventListener('click', checkForActive);

})

export function checkForActive(){
  activeShipping = document.querySelector('.shopping-cart__method input[name="shipping_id"]:checked');
  if(!activeShipping){
    return
  }

  const activeShippingId = activeShipping.getAttribute('id');
  const dataAvaliable = activeShipping.getAttribute("data-payments-avaliable");
  let avaliable;



  if(dataAvaliable){
    avaliable = dataAvaliable.replace(/ /g,'');
    avaliable = avaliable.split(',');
  }
  else return

    console.log(avaliable);

  //console.log(avaliable);

  let paymentMethods = document.querySelectorAll('.shopping-cart__method input[name="payment_id"]');


  paymentMethods.forEach(function(method){
      method.removeAttribute("disabled");
      //console.log(avaliable.indexOf(method.getAttribute('id')));

      if(avaliable.indexOf(method.getAttribute('id')) == -1)
      {
        method.setAttribute("disabled", "disabled");
      }


    });
}

