$(document).ready(()=>{
    ScrollOut({
        //scrollingElement: 'body',
        targets: ['.animated', '.stagger-animated'],
        once: true,
        onShown: function(el) {
    // use the web animation API
        TweenMax.fromTo(el,1.5, {opacity:0,y:70}, {opacity:1,y:0, ease:Power4.easeOut});
        //console.log('spustam');
        if(el.classList.contains('stagger-animated')){
            TweenMax.staggerFromTo(el.children,1.5, {opacity:0,y:70}, {opacity:1, y:0, ease:Power4.easeOut}, .2);
        }
    
        },
      onHidden: function(el) {
        // hide the element initially
        el.style.opacity = 0;
      }
    });
})

