//
// ##     ##    ###    ########   ######
// ##     ##   ## ##   ##     ## ##    ##
// ##     ##  ##   ##  ##     ## ##
// ##     ## ##     ## ########   ######
//  ##   ##  ######### ##   ##         ##
//   ## ##   ##     ## ##    ##  ##    ##
//    ###    ##     ## ##     ##  ######

var submenuVars = {
  mobileMenuClassName: "mobile-menu",
  mobileMenuItemClassName: "menu_item",
  submenuClassName: "submenu",
  hasSubmenuClassName: "has-submenu",
  submenuBackButtonText: "Späť",
  submenuBackButtonClass: "submenu_back",
  submenuBackButtonClasses: ["menu_item"],
};

var submenuBackButtonContent = "<i class='fa fa-chevron-left' aria-hidden='true'></i>" + submenuVars.submenuBackButtonText;


//  ######  ##          ###     ######   ######      ######  ##     ## ########  ##     ## ######## ##    ## ##     ##
// ##    ## ##         ## ##   ##    ## ##    ##    ##    ## ##     ## ##     ## ###   ### ##       ###   ## ##     ##
// ##       ##        ##   ##  ##       ##          ##       ##     ## ##     ## #### #### ##       ####  ## ##     ##
// ##       ##       ##     ##  ######   ######      ######  ##     ## ########  ## ### ## ######   ## ## ## ##     ##
// ##       ##       #########       ##       ##          ## ##     ## ##     ## ##     ## ##       ##  #### ##     ##
// ##    ## ##       ##     ## ##    ## ##    ##    ##    ## ##     ## ##     ## ##     ## ##       ##   ### ##     ##
//  ######  ######## ##     ##  ######   ######      ######   #######  ########  ##     ## ######## ##    ##  #######

// CONSTRUCTOR
const SubMenu = function (item, submenu) {
  this.item = item;
  this.submenu = submenu;
  //this.back = this.submenu.querySelector("." + submenuVars.submenuBackButtonClass);
  this._addEventListeners();
}

// SLIDE SUBMENU IN
SubMenu.prototype.slideIn = function slideIn() {
  const this1 = this;
  //this1.submenu.classList.toggle('submenu_active');
  //this1.item.classList.toggle('submenu_open');
  $(this1.submenu).slideToggle();
  this1.item.classList.toggle('active');
  //console.log("slide in");
}


// EVENT LISTENERS
SubMenu.prototype._addEventListeners = function _addEventListeners() {
  const that = this;

  that.item.querySelector('.icon').addEventListener("click", function (e) {
    that.slideIn();
  })
}


// #### ##    ## #### ########
//  ##  ###   ##  ##     ##
//  ##  ####  ##  ##     ##
//  ##  ## ## ##  ##     ##
//  ##  ##  ####  ##     ##
//  ##  ##   ###  ##     ##
// #### ##    ## ####    ##


// NAJDEME VSETKY MENU ITEMY, KTORE MAJU VNORENE UL (SUBMENU) A SPUSTIME CLASSU
const allMobileMenuItems = document.querySelectorAll("." + submenuVars.mobileMenuClassName + " ." + submenuVars.mobileMenuItemClassName);

allMobileMenuItems.forEach(function (menuItem) {
  if (menuItem.querySelector('ul')) {
    // Pridame classu itemu. ktory oznaci ze polozka ma submenu (.has-submenu)
    menuItem.classList.add(submenuVars.hasSubmenuClassName)

    // SUBMENU
    // pridame classu samotnemu submenu (.submenu)
    const submenu = menuItem.querySelector('ul');
    submenu.classList.add(submenuVars.submenuClassName)

    new SubMenu(menuItem, submenu);
  }
})
